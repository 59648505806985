import {
  // SideNavigation,
  // SideNavigationItem,
  // SideNavigationSubItem,
  Icon,
} from '@ui5/webcomponents-react';
import { Link, useLocation } from 'react-router-dom';

import { useDimensions, useLanguages } from 'utils/hooks';
import { useCurrentSize } from './utils/hooks';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import ProfilePopover from './ProfilePopover';
import Modal from 'components/Modal';

type Props = {
  open: boolean;
  setOpen: Function;
  authorisations: any;
  display: boolean;
  user: any;
};

const MenuItem = styled.div<{ $active: boolean }>`
  width: 100%;
  padding: 0.75rem 1rem;
  background: ${(props) => (props.$active ? '#eeeeee' : 'transparent')};
`;

const TopLevel = styled.li`
  width: 100%;
`;

const SecondLevel = styled.div<{ $active: boolean }>`
  width: 100%;
  padding: 0.5rem 0 0.5rem 2rem;
  background: ${(props) => (props.$active ? '#eeeeee' : 'transparent')};
`;

const SideNavigation = styled.ul`
  width: 100%;
  list-style: none;
`;

const ArrowButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 0.5rem;
  float: right;
  position: relative;
  top: -0.5rem;
`;

const UnilyMsg = styled.p`
  padding: 2em;
`;

const SideNavigationItem = (props) => {
  const loc = useLocation();
  const [open, setOpen] = useState<boolean>(false);
  const active = loc.pathname.includes(props['data-route']);

  // console.log(active, loc.pathname, props['data-route']);

  const getAfterSalesLink = () => {
    const DEV = 'https://sap-dev.ineosautomotive.com/fiori?sap-client=210';
    const SIT = 'https://sap-sit.ineosautomotive.com/fiori#Shell-home';
    const PROD =
      'https://sap.ineosautomotive.com/sap/bc/ui2/flp?sap-client=300&sap-language=EN#Shell-home';

    // console.log('After sales button URL');
    // console.log(window.location.hostname.toLocaleLowerCase());
    if (
      window.location.hostname
        .toLocaleLowerCase()
        .includes('portal.ineosgrenadier.com'.toLocaleLowerCase())
    ) {
      // console.log(PROD);
      return PROD;
    } else if (
      window.location.hostname
        .toLocaleLowerCase()
        .includes('portal-sit.ineosgrenadier.com'.toLocaleLowerCase())
    ) {
      // console.log(SIT);
      return SIT;
    } else {
      // console.log(DEV);
      return DEV;
    }
  };

  const getResourceCenterLink = () => {
    // return 'https://intranet.ineosautomotive.com/sites/global/SitePageModern/8881/network-resource-centre';
    return 'https://toolbox.ineosautomotive.com/sites/global/SitePageModern/8881/network-resource-centre';
  };

  let afterSalesLink = '';
  if (props['data-route'] === 'aftersales') {
    afterSalesLink = getAfterSalesLink();
    if (afterSalesLink === '') return null;
  }

  return (
    <TopLevel>
      <MenuItem $active={active}>
        <Icon
          style={{ position: 'relative', top: '2px', left: '-4px' }}
          name={props.icon}
        />{' '}
        {props['data-route'] == 'aftersales' ? (
          <>
            <a className="nav" href={afterSalesLink} target="_blank">
              {props.text}
            </a>
          </>
        ) : props['data-route'] == 'resourceCenter' ? (
          <>
            <a className="nav" href={getResourceCenterLink()} target="_blank">
              {props.text}
            </a>
            {/* <button onClick={props.openUnily}>{props.text}</button> */}
          </>
        ) : (
          <>
            <Link className="nav" to={props['data-route']}>
              {props.text}
            </Link>
          </>
        )}
        {props.children && (
          <ArrowButton
            onClick={(e) => {
              e.stopPropagation();
              setOpen(!open);
            }}
          >
            <span
              style={{
                display: 'inline-block',
                marginLeft: 'auto',
                color: 'darkgrey',
              }}
              className={`fas ${open ? 'fa-angle-up' : 'fa-angle-down'}`}
            ></span>
          </ArrowButton>
        )}
      </MenuItem>

      {(active || open) && props.children}
    </TopLevel>
  );
};

const SideNavigationSubItem = (props) => {
  const loc = useLocation();
  const active = loc.pathname.includes(props['data-route']);

  return (
    <SecondLevel $active={active}>
      <Icon
        style={{ position: 'relative', top: '2px', left: '-4px' }}
        name={props.icon}
      />{' '}
      <Link className="nav" to={props['data-route']}>
        {props.text}
      </Link>
    </SecondLevel>
  );
};

const styles = {
  L: {
    float: 'left',
    height: '100vh',
    borderRight: '1px solid #ccc',
  },
  M: {
    zIndex: 999,
    position: 'fixed',
    transition: 'left linear 200ms',
    height: '100vh',
    borderRight: '1px solid #ccc',
  },
  S: {
    zIndex: 999,
    position: 'fixed',
    transition: 'left linear 200ms',
    height: '100vh',
    width: '100vw',
    borderRight: '1px solid #ccc',
  },
};

function CustomSideNavigation({
  open,
  setOpen,
  authorisations,
  display,
  user,
}: Props) {
  const { messages: lang } = useLanguages();
  const useStyles = createUseStyles(styles);
  const classes = useStyles();
  const { wdt } = useDimensions();
  const [showUnily, setShowUnily] = useState<boolean>(false);

  const currentSize = useCurrentSize();
  const history = useHistory();
  const handleMenuPress = (e) => {
    if (currentSize === 'M' || currentSize === 'S') setOpen(false);
    history.push(e.detail.item.getAttribute('data-route'));
  };

  useEffect(() => {
    if (!open || wdt > 1275) return;
    // console.log({ open });
    const close = (e) => {
      setOpen(false);
    };
    setTimeout(() => {
      window.addEventListener('click', close);
    }, 10);
    return () => window.removeEventListener('click', close);
    /* eslint-disable-next-line */
  }, [open]);

  // console.log({ user });

  return open ? (
    <div
      id="side-navigation"
      className={classes[currentSize === 'XL' ? 'L' : currentSize]}
      style={{ width: '250px', background: '#fff' }}
    >
      <SideNavigation
      // style={currentSize === 'S' ? { width: '100%' } : {}}
      // onSelectionChange={handleMenuPress}
      >
        {authorisations?.SalesCockpitVhclSOSalesAgentCreate ||
        authorisations?.SalesCockpitVhclSOSalesAgentUpdate ||
        authorisations?.SalesCockpitVhclSOSalesAgentDelete ||
        authorisations?.SalesCockpitVhclSOSalesAgentGet ||
        authorisations?.SalesCockpitVhclSODealerCreate ||
        authorisations?.SalesCockpitVhclSODealerUpdate ||
        authorisations?.SalesCockpitVhclSODealerDelete ||
        authorisations?.SalesCockpitVhclSODealerGet ||
        !!user?.HaveChineseDealerRole ? (
          <SideNavigationItem
            text={lang['home']}
            icon="home"
            data-route="/home"
            className="nav"
          />
        ) : null}

        {authorisations?.SalesCockpitProspectGet &&
        !user?.HaveChineseDealerRole ? (
          <SideNavigationItem
            text={lang['prospects']}
            icon="leads"
            data-route="/prospects"
          />
        ) : null}

        {authorisations?.SalesCockpitBusinessPartnerGet &&
        !user?.HaveChineseDealerRole ? (
          <SideNavigationItem
            text={lang['customers']}
            icon="customer"
            data-route="/customers"
          />
        ) : null}

        {(authorisations?.SalesCockpitVhclSOSalesAgentCreate ||
          authorisations?.SalesCockpitVhclSOSalesAgentUpdate ||
          authorisations?.SalesCockpitVhclSOSalesAgentDelete ||
          authorisations?.SalesCockpitVhclSOSalesAgentGet ||
          authorisations?.SalesCockpitVhclSODealerCreate ||
          authorisations?.SalesCockpitVhclSODealerUpdate ||
          authorisations?.SalesCockpitVhclSODealerDelete ||
          authorisations?.SalesCockpitVhclSODealerGet) &&
        !user?.HaveChineseDealerRole ? (
          <SideNavigationItem
            text={
              authorisations?.SalesCockpitVhclSOSalesAgentGet
                ? lang['vehicles']
                : lang['customerOrders']
            }
            icon="sales-order"
            data-route={
              authorisations?.SalesCockpitVhclSOSalesAgentGet
                ? '/salesOrders/vehicles'
                : '/salesOrders/customerOrders'
            }
          >
            {authorisations?.SalesCockpitVhclReservationOrderGet ? (
              <SideNavigationSubItem
                icon="complete"
                text={lang['Reservations']}
                data-route="/salesOrders/reservations"
              />
            ) : null}

            {authorisations?.SalesCockpitVhclSODealerUpdate && (
              <SideNavigationSubItem
                icon="complete"
                text={lang['stockOrders']}
                data-route="/salesOrders/stockOrders"
              />
            )}

            {display ? (
              <SideNavigationSubItem
                text={lang['pxValuationLC']}
                data-route="/pxvaluation"
              />
            ) : null}
            {/* {authorisations?.SalesCockpitVhclSOSalesAgentGet ||
          authorisations?.SalesCockpitVhclSODealerGet ? (
              <SideNavigationSubItem
                text={lang['vehicleOrders']}
                data-route="/salesOrders/vehicles"
              />
            ) : null} */}
          </SideNavigationItem>
        ) : null}

        {!!user?.HaveChineseDealerRole ? (
          <SideNavigationItem
            icon="complete"
            text={lang['stockOrders']}
            data-route="/salesOrders/stockOrders"
          />
        ) : null}

        {/* UNCOMMENT */}
        {(authorisations?.SalesCockpitVhclQTDealerCreate ||
          authorisations?.SalesCockpitVhclQTDealerGet) &&
        !user?.HaveChineseDealerRole ? (
          <SideNavigationItem
            text={lang['quotations']}
            icon="my-sales-order"
            data-route="/quotations"
          />
        ) : null}

        {authorisations?.SalesCockpitVhclSOSalesAgentGet && (
          <SideNavigationItem
            text={lang['demoLog']}
            icon="car-rental"
            data-route="/demoLog"
          />
        )}

        {authorisations?.SalesCockpitProspectGet &&
        !user?.HaveChineseDealerRole ? (
          <SideNavigationItem
            text={lang['leads']}
            icon="leads"
            data-route="/leads"
          />
        ) : null}

        {authorisations?.SCConditionContractManagement ? (
          <SideNavigationItem
            text={lang['statements']}
            icon="documents"
            data-route="/statements"
          />
        ) : null}

        <SideNavigationItem
          text={lang['supportTicket']}
          data-route="/supportTicket"
          icon="question-mark"
        />

        <SideNavigationItem
          text={lang['infoCentre']}
          icon="arobase"
          data-route="/infoCenter"
        />

        {!user?.HaveItalyDealerRole && (
          <SideNavigationItem
            text={lang['resourceCenter']}
            data-route="resourceCenter"
            icon="document"
            openUnily={() => setShowUnily(true)}
          />
        )}

        <SideNavigationItem
          text={lang['afterSales']}
          data-route="aftersales"
          icon="wrench"
        />
      </SideNavigation>
    </div>
  ) : null;
}

export default CustomSideNavigation;
